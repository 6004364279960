import React, { forwardRef } from 'react';

const Item = forwardRef(({ id, withOpacity, isDragging, style, displayName, isOver, dragDisabled, clickFunc, ...props }, ref) => {
    const inlineStyles = {
        opacity: withOpacity ? '0.5' : '1',
        transformOrigin: '50% 50%',
        height: '160px',
        width: '100px',
        borderRadius: '4px',
        cursor: isDragging ? 'grabbing' : 'grab',
        backgroundColor: isOver ? '#1e5080' : '#1976d2',
        borderTop: isOver? '4px solid red': 'none',
        color: 'white',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        display: 'flex',
        justifyContent: 'center',
        alignItems: '   center',
        textAlign: 'center',
        boxShadow: isDragging  ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        padding: '20px',
        ...style,
    };

    return <div ref={ref} style={inlineStyles} {...props} onClick={clickFunc}>{displayName}</div>;
});

export default Item;