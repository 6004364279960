import CardGrid from './components/CardGrid'
import Settings from './components/Settings';
import { Routes, Route } from "react-router-dom";

function App() {

  return (
    <Routes>
      <Route path="/" element={<CardGrid />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  );
}

export default App;
