export async function getFamily(token) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return await fetch("/api/families", requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            if (data === null) {
                return null;
            }
            if (data.message === "Failed to authenticate token.") {
                throw new Error("Your login has expired. Please re-enter your details.");
            }
            console.log("families data", data);
            return data;
        }

        )
        .catch(error => console.log("Error fetching families: ", error));

}