import { Link as RouterLink } from "react-router-dom";
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as helpers from './helpers';

const Header = ({ loginName }) => {

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar variant="dense" sx={{ display: "flex", justifyContent: "end" }}>
          <Typography>{loginName}</Typography>
            <Box>
            <Button color="inherit" component={RouterLink} variant="text" to="/settings">Settings</Button>
              <Button color="inherit" variant="text" onClick={helpers.profile}>Profile</Button>
              <Button color="inherit" variant="text" onClick={helpers.logout}>Log out</Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

export default Header;