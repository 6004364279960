import React, { useState, useCallback, useRef, useEffect } from "react";
import { DndContext, closestCenter, MouseSensor, TouchSensor, DragOverlay, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import Grid from "./Grid";
import SortableItem from "./SortableItem";
import Item from "./Item";
import MuiGrid from "@mui/material/Grid";
import useToken from "./useToken";
import { SampleDecksData } from "../MockData";
import * as helpers from "./helpers";
import { getDeck, saveDeck } from "../services/Decks";
import { getFamily } from "../services/Family";
import Header from "./Header";
import Card from "./Card";
import FamilyLink from "./FamilyLink";
import { sendPoll } from "../services/Poll";
import { Stack, Menu, MenuItem, Typography } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";

const CardGrid = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

  const addDeckIdsToCards = (cards, deckType) => {
    if (!cards) {
      return null;
    }

    return cards.map((card) => ({
      ...card,
      deckId: `${deckType === "cards" ? card.type : deckType}-${card._id}`, // Use the type key as the deckType if deckType is "cards"
    }));
  };

  const addRandomIdToSampleData = (item) => {
    const randomId = Math.floor(Math.random() * (1e12 - 1e11) + 1e11); // Generate a random 12-digit number
    return {
      ...item,
      id: randomId.toString(), // Add the random ID to the item
    };
  };

  const getSetTotal = (set) => {
    return cardData.filter(card => card.type === set).length;
  };

  const getSetDisplayName = (set) => {

    switch (set) {
      case "me":
        return `${family.me.firstName}`;
      case "them":
        return `${family.them.firstName}`;
      case "unassigned":
        return `Unassigned cards`;
      case "suppressed":
        return `Suppressed cards`;
      default:
        return "";
    }
  };

  const [initialized, setInitialized] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [activeDisplayName, setActiveDisplayName] = useState(null);
  const [overId, setOverId] = useState(null);
  const [dragStartTime, setDragStartTime] = useState(null);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const { token } = useToken();
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [selItem, setSelItem] = useState({});
  const [cardData, setCardData] = useState([]);
  const [cardsVisibility, setCardsVisibility] = useState(() => {
    // Retrieve from local storage
    const savedCardsVisibility = localStorage.getItem('cardsVisibility');
    return savedCardsVisibility ? JSON.parse(savedCardsVisibility) : {unassigned: true};
  });
  const [user, setUser] = useState();
  const [family, setFamily] = useState({ me: {}, them: {} });
  const [unassignedCount, setUnassignedCount] = useState(0);
  const [suppressedCount, setSuppressedCount] = useState(0);
  const [leftUserName, setLeftUserName] = useState("");
  const [rightUserName, setRightUserName] = useState("");
  const [deckId, setDeckId] = useState();
  const [partnerIsOnline, setPartnerIsOnline] = useState(false);
  const [partnerSyncMode, setPartnerSyncMode] = useState(false);
  const [partnerState, setPartnerState] = useState({});
  const cardsLastRetrievedTimestamp = useRef();
  const skipFirstSaveAttempt = useRef(true);
  const [playMode, setPlayMode] = useState(() => {
    // Retrieve from local storage
    const savedPlayMode = localStorage.getItem('playMode');
    return savedPlayMode ? savedPlayMode : "review";
  });
  //Menu action

  const [anchorElActionMenu, setAnchorElActionMenu] = useState(null);
  const openActionMenu = Boolean(anchorElActionMenu);
  const handleClickActionMenu = (event) => {
    setAnchorElActionMenu(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorElActionMenu(null);
  };

  const handlePlayMode = (event, newMode) => {
    setPlayMode(newMode);
  };

  useEffect(() => {
    localStorage.setItem('playMode', playMode);
    switch (playMode) {
      case "play":
        setCardsVisibility({ me: true, them: true });
        break;
      case "review":
        setCardsVisibility({ unassigned: true });
        break;
        case "mycards":
          setCardsVisibility({ me: true });
          break;
      default:
        break;
    }
  }, [playMode]);


  const resetCards = async () => {
    let resetConfirm = window.confirm("This will reset ALL your card data - be VERY sure you want to do this!");
    if (resetConfirm === true) {
      try {
        const cards = [...SampleDecksData.map(addRandomIdToSampleData)];
        await saveDeck(token, cards, deckId);
        console.log("Reset cards");
        // window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const refreshCards = async (_deckId) => {
    if (!_deckId) return;
    const cards = await getDeck(token, _deckId);

    cardsLastRetrievedTimestamp.current = cards.lastUpdate;
    let totalCards = cards.cards?.length || 0;

    if (cards === null || totalCards === 0) {
      console.log("User has no cards, load sample data");
      resetCards();
    } else {
      setCardData(addDeckIdsToCards(cards.cards, "cards"));
      if (selItem) {
        let updatedSelCard = cards.cards.find((e) => e["_id"] === selItem._id);

        //only update the bits that have actually been changed
        let updatedCardData = { ...selItem };
        Object.keys(selItem).forEach((key) => {
          if (selItem[key] !== updatedSelCard[key]) {
            console.log("Updated value:", updatedSelCard[key]);
            updatedCardData[key] = updatedSelCard[key];
          }
        });
        setSelItem(updatedCardData);
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      setInitialized(true);
      console.log("init");
      await helpers.requestAuthentication();
      if (!token) {
        return;
      }

      const user = helpers.getUserData();
      if (!user) {
        return;
      }
      setUser(user);

      const family = await getFamily(token);

      if (!family) {
        return;
      }
      if (!family.me || !family.me.firstName) {
        family.me = {};
        family.me.firstName = "";
      }
      if (!family.them || !family.them.firstName) {
        family.them = {};
        family.them.firstName = "";
      }
      setFamily(family);
      setLeftUserName(family.me.firstName);
      setRightUserName(family.them.firstName);
      setDeckId(family.deck);

      if (!family.deck) {
        console.log("User has no cards, using sample data");
        resetCards();
      } else {
        console.log("User has cards, loading deck ID", family.deck);
      }

      await refreshCards(family.deck);
    };
    if (!initialized)
      init();
  });

  useInterval(() => {
    sendPoll(token, {
      state: {
        isCardOpen: isCardOpen,
        selCard: selItem?._id || 0,
        partnerSyncMode: partnerSyncMode,
        playMode: playMode,
        currentFocusTag: document.activeElement.tagName,
        currentFocusElemName: document.activeElement.name,
      },
    }).then((res) => {
      if (!family.name || !family.me._id || !family.them._id) {
        console.log("No family set up", family);
        // if (res.family !== null) window.location.reload();
      } else {
        const partner = family.me._id === user._id ? family.them : family.me;

        if (res.family.them.status === "online") {
          setPartnerState({
            ...partnerState,
            ...res.family.them.state,
            firstName: partner.firstName,
          });
          setPartnerIsOnline(true);
        } else {
          setPartnerIsOnline(false);
        }
        if (res.family.cardsLastUpdated && cardsLastRetrievedTimestamp.current && res.family.cardsLastUpdated !== cardsLastRetrievedTimestamp.current) {
          console.log("server cards: " + res.family.cardsLastUpdated, "|", "my cards: " + cardsLastRetrievedTimestamp.current);
          console.log("Client out of date, getting updated cards...");
          skipFirstSaveAttempt.current = true;
          refreshCards(deckId);
        } else {
          cardsLastRetrievedTimestamp.current = res.family.cardsLastUpdated;
        }

        if (partnerSyncMode) {
          if (partnerState.playMode !== playMode) handlePlayMode(null, partnerState.playMode);

          if (partnerState.isCardOpen) {
            let partnerCard = cardData.find((e) => e["_id"] === partnerState.selCard);
            setSelItem(partnerCard);
            setIsCardOpen(true);
          } else {
            setSelItem();
            setIsCardOpen(false);
          }
        }
      }
    });
  }, 1000);

  useEffect(() => {
    // Save to local storage
    localStorage.setItem('cardsVisibility', JSON.stringify(cardsVisibility));
  }, [cardsVisibility]); 

  useEffect(() => {
    let newCardIds = [];

    if (cardData && cardData.length > 0) {
      cardData.forEach((card) => {
        newCardIds.push(card._id);
      });
    }

    if (localStorage.getItem("currCards") && newCardIds.length > 0) {
      const currCardsString = localStorage.getItem("currCards");
      const currCards = JSON.parse(currCardsString);
      localStorage.removeItem("currCards");

      let cardDifference = newCardIds.filter((x) => !currCards.includes(x));
      if (cardDifference.length > 0) {
        let updatedSelCard = cardData.find((e) => e["_id"] === cardDifference[0]);
        setSelItem(updatedSelCard);
        setIsCardOpen(true);
      }
    }
  }, [cardData]);

  useEffect(() => {
    if (!cardData || !family || !token) return;

    let totalCards = cardData?.length || 0;
    let unassignedCount = 0;
    let suppressedCount = 0;
    cardData.forEach((card) => {
      if (card.type === "unassigned") unassignedCount++;
      if (card.type === "suppressed") suppressedCount++;
    });
    setUnassignedCount(unassignedCount);
    setSuppressedCount(suppressedCount);

    if (cardData && totalCards !== 0 && family["name"]) {
      const timer = setTimeout(() => {
        if (skipFirstSaveAttempt.current === true) {
          skipFirstSaveAttempt.current = false;
          return;
        }
        saveDeck(token, cardData, deckId).then((res) => {
          console.log("Saved cards");
          cardsLastRetrievedTimestamp.current = res.timestamp;
        });
      }, 500);
      return () => clearTimeout(timer);
    } else {
      console.log("No family set up, so not saving", family);
    }
  }, [cardData, family, token, deckId]);

  useEffect(() => {
    setSelItem((prevSelItem) => {
      if (prevSelItem && prevSelItem._id && cardData) {
        let updatedSelCard = cardData.find((e) => e["_id"] === prevSelItem._id);
        return updatedSelCard;
      }
    });
  }, [cardData]);

  const openCards = (_type) => {
    // Filter cardData for the desired type and select the first item
    const filteredCardData = cardData.filter((item) => item.type === _type);

    if (filteredCardData.length > 0) {
      setSelItem(filteredCardData[0]);
      setIsCardOpen(true);
    }
  };

  const newCard = () => {
    setSelItem({
      name: "New custom card",
      suit: "none",
      type: "unassigned",
      isCustom: true,
    });
    setIsCardOpen(true);
  };

  const exportCards = () => {
    const filename = "data.json";

    // Create a new array of cards without _id and deckId keys
    const cleanedCardData = cardData.map(({ _id, deckId, ...rest }) => rest);

    // Format the JSON string with 2 spaces of indentation
    const jsonStr = JSON.stringify(cleanedCardData, null, 2);

    let element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(jsonStr));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const shuffleCards = () => {
    let newDeck = [...cardData];
    //update the type for all cards to "unassigned"

    setCardData(newDeck);
  };

  const handleClick = (card) => {
    setSelItem(card);
    setIsCardOpen(true);
  };

  const SortableGrid = ({ items, itemType, onClick }) => {
    // Calculate the number of SortableGrid components
    const numGrids = Object.entries(
      cardData.reduce((groups, card) => {
        (groups[card.type] = groups[card.type] || []).push(card);
        return groups;
      }, {})
    ).filter(([type, cards]) => cardsVisibility[type]).length;
  
    return (
      <MuiGrid item xs={numGrids > 1 ? 12 : 6} sm={numGrids > 1 ? 6 : 12}>
        <Typography variant="h4" sx={{ marginBottom: '-60px !important' }}>{getSetDisplayName(itemType)} ({getSetTotal(itemType)})</Typography>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Grid columns={isMobile ? 2 : (numGrids > 1 ? 2 : 4)}>
            {items.map((item) => (
              <SortableItem key={item.deckId} id={item.deckId} isOver={item.deckId === overId} displayName={item.name} dragDisabled={isMobile} clickFunc={() => onClick(item)} />
            ))}
          </Grid>
        </SortableContext>
      </MuiGrid>
    );
  };

  function arrayInsert(array, index, value) {
    let newArray = array.slice();
    newArray.splice(index, 0, value);
    return newArray;
  }

  function arrayRemove(array, index) {
    let newArray = array.slice();
    newArray.splice(index, 1);
    return newArray;
  }

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
    const activeItem = cardData.find(item => item.deckId === event.active.id);
    setActiveDisplayName(activeItem.name);
    setDragStartTime(Date.now()); // Save the start time
    console.log("handleDragStart", activeItem.name);
  }, [cardData]);

  const handleDragOver = useCallback((event) => {
    const { active, over } = event;
    console.log("handleDragOver", active.id, over?.id);
    if (active.id !== over?.id) {
      setOverId(over?.id);
    } else {
      setOverId(null);
    }
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    const dragEndTime = Date.now();
    const dragDuration = dragEndTime - dragStartTime;
    const dragDistance = Math.sqrt(Math.pow(event.delta.x, 2) + Math.pow(event.delta.y, 2));
    const dragMsThreshold = 200;
    const dragThreshold = 5;

    setOverId(null);
    setDragStartTime(null);

    if (active.id !== over?.id) {
      handleCardMove(active, over);
    } else if (dragDistance < dragThreshold && dragDuration < dragMsThreshold) {
      handleCardClick(active);
    }

    setActiveId(null);

    function handleCardMove(active, over) {
      const source = active.id.split("-")[0];
      const destination = over.id.split("-")[0];
      const oldIndex = cardData.findIndex((item) => item.deckId === active.id);
      const newIndex = cardData.findIndex((item) => item.deckId === over.id);
      let newCardData = [...cardData];

      if (source === destination) {
        newCardData = arrayMove(cardData, oldIndex, newIndex);
      } else {
        let activeItem = { ...cardData[oldIndex] };
        activeItem.deckId = `${destination}-${activeItem.deckId.split("-")[1]}`;
        activeItem.type = destination;
        newCardData = arrayRemove(cardData, oldIndex);
        newCardData = arrayInsert(newCardData, newIndex, activeItem);
      }

      setCardData(newCardData);
    }

    function handleCardClick(active) {
      const selectedCard = cardData.find((card) => card.deckId === active.id);
      handleClick(selectedCard);
    }
  }, [cardData, dragStartTime]);

  return (
    <>
      <Header loginName={user?.firstName} />
      <Stack mt={2} direction={{ xs: "column", sm: "row", md: "row" }} alignItems="center" justifyContent="space-between" spacing={{ xs: 1, sm: 2, md: 4 }}>
        <Box>
          <ToggleButtonGroup value={playMode} exclusive onChange={handlePlayMode}>
          <ToggleButton value="mycards">My Cards</ToggleButton>
            <ToggleButton value="review">Review</ToggleButton>
            <ToggleButton value="play">Play</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <Stack direction={{ xs: "column", sm: "row", md: "row" }} alignItems="center" spacing={2}>
            {cardsVisibility["me"] && cardsVisibility["them"] && (
              <Box>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    openCards("unassigned");
                  }}
                >
                  DEAL REMAINING {unassignedCount} CARDS
                </Button>
              </Box>
            )}
            {partnerIsOnline && !partnerState.partnerSyncMode && (
              <Box>
                <Typography mr={1} component="span" variant="body2">
                  {family.me._id === user._id ? family.them.firstName : family.me.lastName} is online
                </Typography>
                {partnerSyncMode === false && (
                  <Button
                    size="small"
                    color="success"
                    variant="outlined"
                    onClick={(e) => {
                      setPartnerSyncMode(true);
                    }}
                  >
                    Follow
                  </Button>
                )}
                {partnerSyncMode === true && (
                  <Button
                    size="small"
                    color="warning"
                    variant="outlined"
                    onClick={(e) => {
                      setPartnerSyncMode(false);
                    }}
                  >
                    Un-follow
                  </Button>
                )}
              </Box>
            )}
            {partnerIsOnline && partnerState.partnerSyncMode && (
              <Box>
                <Typography mr={1} component="span" variant="body2">
                  {family.me._id === user._id ? family.them.firstName : family.me.lastName} is following YOU
                </Typography>
              </Box>
            )}
            <Button
              id="action-button"
              variant="outlined"
              color="primary"
              aria-controls={openActionMenu ? "action-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openActionMenu ? "true" : undefined}
              onClick={handleClickActionMenu}
            >
              More...
            </Button>
            <Menu
              id="action-menu"
              anchorEl={anchorElActionMenu}
              open={openActionMenu}
              onClose={handleCloseActionMenu}
              MenuListProps={{
                "aria-labelledby": "action-button",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  newCard();
                  setAnchorElActionMenu(null);
                }}
              >
                New card
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  shuffleCards();
                  setAnchorElActionMenu(null);
                }}
              >
                Reshuffle cards
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setCardsVisibility({ me: true });
                  setPlayMode(null);
                  setAnchorElActionMenu(null);
                }}
              >
                {family.me.firstName}'s hand
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setCardsVisibility({ them: true });
                  setPlayMode(null);
                  setAnchorElActionMenu(null);
                }}
              >
                {family.them.firstName}'s hand
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setCardsVisibility({ unassigned: true });
                  setPlayMode(null);
                  setAnchorElActionMenu(null);
                }}
              >
                Unassigned cards ({unassignedCount})
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setCardsVisibility({ suppressed: true });
                  setPlayMode(null);
                  setAnchorElActionMenu(null);
                }}
              >
                Suppressed cards ({suppressedCount})
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  exportCards();
                  setAnchorElActionMenu(null);
                }}
              >
                Export cards
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  resetCards();
                  setAnchorElActionMenu(null);
                }}
              >
                Reset all cards
              </MenuItem>
            </Menu>
          </Stack>
        </Box>
      </Stack>

      {(!family["me"]["firstName"] || !family["them"]["firstName"]) && <FamilyLink family={family} />}
      {!isCardOpen && (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
          <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center' }}>
            <MuiGrid container spacing={12}>
              {cardData &&
                Array.isArray(cardData) &&
                Object.entries(
                  cardData.reduce((groups, card) => {
                    (groups[card.type] = groups[card.type] || []).push(card);
                    return groups;
                  }, {})
                ).map(([type, cards]) => cardsVisibility[type] && <SortableGrid key={type} items={cards} itemType={type} onClick={handleClick} />)}
            </MuiGrid>
          </Container>
          <DragOverlay>{activeId ? <Item id={activeId} displayName={activeDisplayName} /> : null}</DragOverlay>
        </DndContext>
      )}
      {isCardOpen && (
        <Card
          selCard={selItem}
          setSelCard={setSelItem}
          leftUserName={leftUserName}
          rightUserName={rightUserName}
          cardData={cardData}
          deckId={deckId}
          setCardData={setCardData}
          isCardOpen={isCardOpen}
          setIsCardOpen={setIsCardOpen}
          token={token}
          family={family}
          user={user}
          cardsLastRetrievedTimestamp={cardsLastRetrievedTimestamp}
          partnerIsOnline={partnerIsOnline}
          partnerSyncMode={partnerSyncMode}
          setPartnerSyncMode={setPartnerSyncMode}
          partnerState={partnerState}
          playMode={playMode}
          getSetDisplayName={getSetDisplayName}
        />
      )}
    </>
  );
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default CardGrid;
