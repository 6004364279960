import { useState } from 'react';
import * as helpers from './helpers'

export default function useToken() {
  const getToken = () => {
    return helpers.getToken()
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
      helpers.setToken(userToken);
      setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}