export async function getSettings(token) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return await fetch("/api/useroptions", requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            if (data === null) {
                return null;
            }
            if (data.message === "Failed to authenticate token.") {
                throw new Error("Your login has expired. Please re-enter your details.");
            }
            return data;
        }

        )
        .catch(error => console.log("Error fetching settings: ", error));

}

export async function createSetting(token, data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data)
    };

    fetch("/api/useroptions", requestOptions)
        .then(function () {
            console.log("Setting saved.");
        })
        .catch(error => console.log('error', error));
}

export async function updateSetting(token, data, id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(data)
    };

    fetch("/api/useroptions/" + id, requestOptions)
        .then(function () {
            console.log("Setting updated.");
        })
        .catch(error => console.log('error', error));
}

export async function deleteSetting(token, id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
    };

    fetch("/api/useroptions/" + id, requestOptions)
        .then(function () {
            console.log("Setting deleted.");
        })
        .catch(error => console.log('error', error));
}