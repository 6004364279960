import {
  getSettings,
  deleteSetting,
  createSetting,
  updateSetting,
} from "../services/UserOptions";
import React, { useEffect, useState } from "react";
import useToken from "./useToken";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import * as helpers from "./helpers";

const Settings = (props) => {
  const { token } = useToken();
  const [settings, setSettings] = useState([]);
  const [linkCode, setLinkCode] = useState("");
  const [familyId, setFamilyId] = useState("");
  const [nickname, setNickname] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    //take the value of linkCode and update the setting item with the name "shareKey" to that value
    const id = settings.find((item) => item.name === "shareKey")._id;
    await updateSetting(token, { value: linkCode }, id);
    //take the value of familyId and update the setting item with the name "linkedToUser" to that value
    if (familyId !== null) {
      const settingItem = settings.find((item) => item.name === "linkedToUser");
      const id2 = settingItem ? settingItem._id : null;
      if (id2 === null) {
        await createSetting(token, { name: "linkedToUser", value: familyId });
      } else {
        await updateSetting(token, { value: familyId }, id2);
      }
    }

    const nicknameSetting = settings.find((item) => item.name === "nickname");
    const nicknameId = nicknameSetting ? nicknameSetting._id : null;
    if (nicknameId === null) {
      await createSetting(token, {
        name: "nickname",
        value: nickname || helpers.getUserData.firstName,
      });
    } else {
      await updateSetting(
        token,
        { value: nickname || helpers.getUserData.firstName },
        nicknameId
      );
    }
  };

  useEffect(() => {
    getSettings(token).then((settings) => {
      console.log(settings);
      //check the settings array for an item with the name "shareKey". If it exists, set the linkCode to the value of that item.
      //if it doesn't exist, set the linkCode to Math.random().toString(36).slice(7).toUpperCase()
      if (settings.find((item) => item.name === "shareKey")) {
        setLinkCode(settings.find((item) => item.name === "shareKey").value);
      } else {
        //create the setting
        createSetting(token, {
          name: "shareKey",
          value: Math.random().toString(36).slice(7).toUpperCase(),
        });
        setLinkCode(Math.random().toString(36).slice(7).toUpperCase());
        getSettings(token).then((settings) => setSettings(settings));
      }

      //check the settings array for an item with the name "linkedToUser". If it exists, set the familyId to the value of that item.
      //if it doesn't exist, set the familyId to null
      if (settings.find((item) => item.name === "linkedToUser")) {
        setFamilyId(
          settings.find((item) => item.name === "linkedToUser").value
        );
      }

      if (settings.find(item => item.name === "nickname")) {
        setNickname(settings.find(item => item.name === "nickname").value);
      } else {
        createSetting(token, {
          name: "nickname",
          value: helpers.getUserData().firstName,
        });
        setNickname(helpers.getUserData().firstName);
      }

      setSettings(settings);
    });
  }, [token]);

  const deleteFamily = async (e) => {
    //find the id of the setting item with the name "linkedToUser" in settings
    const id = settings.find((item) => item.name === "linkedToUser")._id;
    await deleteSetting(token, id);
    //set the familyId to null
    setFamilyId(null);
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box align="center">
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Box>
        <Box>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
            <TextField
              disabled
              fullWidth
              margin="normal"
              label="Link Code"
              value={linkCode}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Family ID"
              value={familyId}
              onChange={(e) => setFamilyId(e.target.value)}
            />
            <Stack>
              <Button
                disabled={!familyId}
                variant="text"
                onClick={deleteFamily}
              >
                Unlink Family
              </Button>
            </Stack>
            <Box justifyContent="space-between" display="flex" mt={2}>
              <Button variant="contained" type="submit">
                Submit
              </Button>
              <Button variant="text" component={RouterLink} to="/">
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};
export default Settings;
