export const SampleDecksData = [
    {
        "userId": "",
        "name": "Extracurriculars (Kids, Sports)",
        "type": "me",
        "suit": "out",
        "grind": true,
        "definition": "If you think this card means just showing up on the right field at the right time to cheer on your kid, you couldn't be more wrong. While the Execution can be rewarding when you're there to watch your child hit a home run, the Conception and Planning are more tedious and require tremendous forethought. What are the equipment needs? What's the game schedule? And when do you have to provide the team snack?",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Goal is to always have Ollie having a sporting-like activity each term (excepting swimming). Make sure discussions have been had and he's signed up in time for the team/period. \n\nMaking sure he's got all the gear he needs (looking for second hand first, ie gumtree etc)\n\nMaking sure it's scheduled on our family calendar.\n\nMaking sure Erin knows about the cost\n\nVolunteering where possible"
    },
    {
        "userId": "",
        "name": "Garbage",
        "type": "me",
        "suit": "home",
        "grind": true,
        "definition": "Really, how much darn trash can one family create? Staggering. To CPE this card means, at minimum, you take out the trash before the garbage truck turns the corner of your block (which day is it again?). Whoever holds this card is responsible for anticipating when trash bags are running low, and a word to the wise: Don't you dare sit back down on the couch until you put a new bag back in the trash can!",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Min once a month, clean all 3 bins\nAlso clean the inside of the bin cupboard at the same time"
    },
    {
        "userId": "",
        "name": "Laundry",
        "type": "me",
        "suit": "home",
        "grind": true,
        "definition": "If you haven't ever CPE'd this card, would you like to take a guess at how many loads of laundry your family generates weekly? A freakin' lot, that's my guess. If you never go a day without clean socks and your daughter can always find her favorite rainbow shirt, then be sure to thank this cardholder, who not only throws the load in the washer and dryer but also folds it and puts it all away. If laundry tends to pile up and multiply in your home, it's time to re-deal this card and come up with a new standard before the next cycle begins.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Things should not be this wrinkly when put away"
    },
    {
        "userId": "",
        "name": "Adult Friendships (Player 1)",
        "type": "me",
        "suit": "magic",
        "grind": false,
        "definition": "Each of you gets this card because friends are key to your health, happiness, resilience, career, and sanity. Whether it's grabbing coffee after school drop-off, a dinner date, a networking event, or a weekend trip away, nurturing friendships will reap huge dividends. Friendships are not a luxury you'll make time for in the future so use this card guilt-free now. No partner resentment allowed either, as a girls' or guys' night out is about so much more than a cocktail. It's about cultivating relationships that will support and sustain you along with boosting the health of your marriage.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Auto",
        "type": "me",
        "suit": "out",
        "grind": false,
        "definition": "Do you know what happens if you don't put the new registration sticker on your car? You get a ticket that could have easily been avoided, that's what. This cardholder handles all items relating to your family's car(s), including putting that sticker on in a timely manner, paying for that darn ticket, renewing insurance, handling maintenance, getting car washes, regularly filling up with gas, and getting the oil checked so that you feel safe and secure in the family car.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Must deal with regular service for both cars\nMake sure registration is paid yearly for both cars\nMake sure insurance is up to date / paid, or find new insurance when required"
    },
    {
        "userId": "",
        "name": "Electronics & IT",
        "type": "me",
        "suit": "out",
        "grind": false,
        "definition": "When your cell phone screen shatters, who ya gonna call? This cardholder! When your Wi-Fi isn't working or your DVR is full, who ya gonna call? This cardholder! You need not work at the cable company or have tech know-how, but you do need to have ample time and patience to CPE this card, especially when you have to wait six hours for the cable guy. New cords, chargers, and ink cartridges? Also you. Waiting in line to get your kid his first phone? Still you.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Estate Planning & Life Insurance",
        "type": "me",
        "suit": "caregiving",
        "grind": false,
        "definition": "Who will take care of your kids if something catastrophic happens to you and your partner? Of course both partners will plan together as you think about long-term security for your family and life-and-death medical decisions, but ultimately this cardholder is responsible for making sure the job gets done so you can all sleep at night knowing there's a worst-case scenario plan (and insurance funds) in place.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Get wills when we can afford (2024)"
    },
    {
        "userId": "",
        "name": "Grooming & Wardrobe (Player 1)",
        "type": "me",
        "suit": "caregiving",
        "grind": false,
        "definition": "Looking and feeling good, especially after children, doesn't come easy. (There's a reason that beauty is a $445 billion industry.) Even if you're a DIY kind of woman, it still takes time to slather on moisturizer, pluck your eyebrows, style your hair, and put on lipstick. Kick it up a notch and you may add mani/pedis, eyelash extensions, and waxing to your beauty regimen. Add in shopping for clothes (in various sizes for pre-pregnancy, post-pregnancy, nursing, and every other reason your weight fluctuates) and you've got a potentially expensive and time-consuming card in your hands.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Health Insurance",
        "type": "me",
        "suit": "caregiving",
        "grind": false,
        "definition": "It's a blessing to have health insurance. The paperwork that goes along with it? Not so much. Unless you somehow find a medical practice that handles all the billing for you, this cardholder needs to know every time anyone in your family goes to the doctor, makes sure the appropriate paperwork is filed for insurance, keeps track of reimbursements, and steps in when things go awry. Every time you see an envelope from your insurance provider (consult with the mail cardholder to ensure you receive), say a prayer that you've been covered.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Home Maintenance",
        "type": "me",
        "suit": "home",
        "grind": true,
        "definition": "How many people does it take to change a lightbulb? In this game, just one, and it's the person holding this card. You're also responsible for unclogging toilets, calling a repairman when the air conditioner breaks (inevitably in the height of summer), finding the manual for the vacuum cleaner that's spewing dust, and every other home maintenance to-do that requires hands-on CPE.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Meals (Weekday Breakfast)",
        "type": "me",
        "suit": "home",
        "grind": true,
        "definition": "One mom I spoke to lamented that she's held this card every single day since her kids were old enough to eat solids. Every. Single. Day. Prepare to frequently re-deal full CPE of this nonnegotiable Daily Grind unless serving oatmeal and toast at breakneck speed is your jam. Dishes left in the sink and milk spilled on the counter isn't on you unless you hold the dishes and tidying cards. Still, be respectful before you zoom out the door.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Morning Routine (Kids)",
        "type": "me",
        "suit": "caregiving",
        "grind": true,
        "definition": "When was the last time you slept in, pooped in peace, or leisurely read the newspaper while drinking coffee in the morning? Before kids, if you're holding this card. This cardholder is prepared for daily combat before sunrise with a baby and early still with school-aged kids who often need rousing from bed and help getting dressed, hair and teeth brushed, sunscreen applied, and ushering out the door. At least the meals (weekday breakfast) cardholder takes the lead on getting them fed.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Packing & Unpacking (Kids, Local)",
        "type": "me",
        "suit": "out",
        "grind": true,
        "definition": "If you use the last diaper in the baby's diaper bag and don't replace it, how many diapers will be left the next time you need to change an explosive poop on the go? Exactly. It's got to be restocked, just as backpacks need to be packed (and unpacked) daily with library books, lunch, homework, water bottles, clothes for after-school activities, and about a million other things you'll forget if you don't have a system in place. Bonus duties: packing for sleepovers, packing and replacing the change of clothes in the school cubby as needed, and hitting up the school's lost and found when only one glove makes it home.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Childcare bags always packed with spare clothes. Seasonally checking for sizing etc\n\nEvery school holidays each bag should be emptied and checked!"
    },
    {
        "userId": "",
        "name": "Pets",
        "type": "me",
        "suit": "caregiving",
        "grind": true,
        "definition": "When you got your pet, the deal was that the kids would take on a lot of the responsibility. Ha! Now, here you are with a bundle of furry love . . . and a bundle of work. Your to-do list depends on what kind of animal you have, but across the board this cardholder typically CPEs food, veterinary appointments, grooming, pet supplies, finding a pet sitter for when you're away, and the least fun but most important - taking out the kitty litter or being on the ready with poop bag in hand.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Returns & Store Credits",
        "type": "me",
        "suit": "out",
        "grind": false,
        "definition": "If it seems as if there's always something you've bought that needs to be returned or exchanged - typically without the receipt and on the opposite side of town - then this cardholder will be quite busy. Store credit? Gift cards from your mother-in-law? Sure, they were for your kids, but who's gonna know if you used one to buy those cute shoes on sale?",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Self-Care (Player 1)",
        "type": "me",
        "suit": "caregiving",
        "grind": false,
        "definition": "Each of you gets a self-care card regardless of how many children and other responsibilities you have. For some, a daily multivitamin, a good book, and a hot bath fit the bill. Others may have weekly therapy or wellness appointments, and also set aside regular time to exercise. No guilt or shame allowed. Take this important time for you and allow your partner to do the same.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Birthday Celebrations (Your Kids)",
        "type": "me",
        "suit": "magic",
        "grind": false,
        "definition": "Have you ever tried shoving a dozen balloons into the trunk of your car? It's like playing a game of whack-a-mole but you get no prize. How about copying and pasting email addresses for the whole class into your online invitation? Time suck. These are just illustrations of the many tasks you'll handle when planning your child's birthday party. Even if you keep it simple with a family dinner at home, this cardholder might be the one who battles rush-hour traffic to pick up a cake at a favorite bakery before it closes. Don't forget candles, but no need to buy a present unless you're also the gifts (family) lead.",
        "conception": "Ollie 2-3 friends\nAri no party, something special",
        "planning": "",
        "execution": "",
        "msc": "Adam to do 2024"
    },
    {
        "userId": "",
        "name": "Clothes & Accessories (Kids)",
        "type": "me",
        "suit": "caregiving",
        "grind": false,
        "definition": "News flash: Your kids' shoes and clothes - in sizes that fit -  don't magically show up in their closets. Someone needs to CPE what clothes and accessories to buy, get to the appropriate stores, clean out drawers periodically, and hand off used and outgrown clothes to the tidying up, organizing, and donations cardholder. Suggested timeline: If your child's feet have blisters, you've probably waited too long to buy new shoes. Exception: If there is a camp or school one-off clothing need (see Case of the Color War), this responsibility stays with the school breaks (summer) or homework cardholder.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Work with Erin on the big ones\n\nnotice if they are growing out of stuff / consider a coming change of season may mean a refresh is needed\npull out all existing, work out what can be kept (if they will grow out in two months, get rid of it)\nCheck in cubboard for more clothes\nWhen buying, look online for bundles, then if not go shopping\nOld clothes go into office, sorted by season\n\nAri\nGo into office and plunder the boxes for the next sizes, then top up from shops when needed\nOld clothes go straight to op-shop\n\nTry and get the kids to participate when buying clothes\n\nSTART OF SCHOOL HOLIDAYS"
    },
    {
        "userId": "",
        "name": "Tidying up, Organizing & Donations",
        "type": "me",
        "suit": "home",
        "grind": true,
        "definition": "This never-ending work includes making beds, putting toys away, finding the Legos under the couch (preferably before you buy a whole new set), and wrapping up the half a banana sitting on the counter before fruit flies infest your home. This 784card is a hot-button issue for many couples who often disagree on a standard for tidy, so before one of you claims it, get specific about how long you can both live with the toy closet that's bursting at the seams, all the random crap accumulating under the stairwell, and the donation bags that have been sitting in the trunk of the family car for months. Unless your aspiration is to star on Hoarders (and your partner agrees to costar), you may want to binge a few episodes of Tidying Up with Marie Kondo.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "START OF SCHOOL HOLIDAYS\n\nErin: Clothes stuff should be in the clothes section. Should just be about toys, books etc here"
    },
    {
        "userId": "",
        "name": "Bathing & Grooming (Kids)",
        "type": "me",
        "suit": "caregiving",
        "grind": true,
        "definition": "The nature of this cardholder's responsibilities changes depending on your kids' ages, but the point is that your kids need to brush their teeth, bathe, wash their faces, cut their nails, and brush their hair. Over and over again. Tip! Pay attention to your kids' developing needs. If you notice a suspicious scent every time your teenager is within five feet, it's probably time for this CPEer to buy deodorant and make sure your kid knows how often to use it. Daily!",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "Toes nails and feet nails need to not be GROSS\n\nHair shouldn't be all shaggy "
    },
    {
        "userId": "",
        "name": "At home monthly date night (alternates)",
        "type": "unassigned",
        "suit": "magic",
        "grind": false,
        "definition": "Nice dinner, wine, activity etc\n\nPlan for a specific Sat night in the month",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": "October - Adam\nNovember - Erin\nDecember - Adam\nJanuary - Erin\nFebuary - Adam\nMarch - Erin\nApril - Adam"
    },
    {
        "userId": "",
        "name": "Adult Friendships (Player 2)",
        "type": "them",
        "suit": "magic",
        "grind": false,
        "definition": "Each of you gets this card because friends are key to your health, happiness, resilience, career, and sanity. Whether it's grabbing coffee after school drop-off, a dinner date, a networking event, or a weekend trip away, nurturing friendships will reap huge dividends. Friendships are not a luxury you'll make time for in the future so use this card guilt-free now. No partner resentment allowed either, as a girls' or guys' night out is about so much more than a cocktail. It's about cultivating relationships that will support and sustain you along with boosting the health of your marriage.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Birthday Celebrations (Other Kids)",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "From RSVPing to buying presents, the birthday party circuit for your kids is riddled with opportunities for missteps . . . like when you're already out the door and realize it's a princess dress-up theme. Oops. And why didn't anyone warn you how many weekend hours you'd be spending at other kids' birthday parties chitchatting with parents you hardly know? Do a happy dance if siblings are invited so you can have two kids in the same place at the same time. Note! If you have two or more kids who have to be at separate birthday celebrations, you and your partner can both hold this card separately. A Kid Split means you both CPE the same card at the same time but for separate kids. See page 267 for more about splitting cards. Post Note! This card pairs with the transportation (kids) and gifts (VIPs) cards. Meaning, if you RSVP'd yes to the birthday party at the trampoline park, you are responsible for getting your son there (gift in hand) and picking him up afterward.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Calendar Keeper",
        "type": "them",
        "suit": "out",
        "grind": true,
        "definition": "You know how some business executives have an assistant to schedule all their meetings and events? Well, your family is busier but you have no one. It's up to you to create a system that works for your family so everyone knows what's on the calendar day to day and week to week and plans accordingly. Just when you think you've nailed the schedule and hung it on the wall, you'll find out that soccer practice is on the same day as Boy Scouts and you'll be back to square one. Add in your own commitments along with your spouse's, and you've got a job with more moving parts than a millipede.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Cash & Bills",
        "type": "them",
        "suit": "out",
        "grind": true,
        "definition": "How darn hard can it be to get to the bank so you have adequate cash on hand (after consulting the money manager, who can help define the standard for adequate)? Enter this cardholder, who will set up a system that prevents all interested parties from ever uttering or hearing, Sorry, hon, I've only got five bucks. This CPEer also handles the payment (on time!) of all bills, whether online, checks in the mail, or auto-pay, and balances the checkbook (or the virtual equivalent). Cozy up with the mail cardholder, who will ensure paper bills come your way.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Childcare Helpers (Kids)",
        "type": "them",
        "suit": "home",
        "grind": true,
        "definition": "It takes a village, and you're fortunate if your village includes a nanny, babysitter, family caregivers, or others who pitch in with the kids. It can be a lifesaver to have the help, but it still requires someone to Conceive and Plan before Nanny Poppins or Grandma Shirley shows up to help Execute. They don't magically appear when you want them to, so schedules need to be managed, along with payment, delegation of responsibilities, and ongoing communication. Heads up: When your helper cancels or quits, this cardholder is not necessarily the one who drops everything. Rather, engage in Fair Play immediately to re-deal the applicable cards, for example, transportation (kids) and watching.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Civic Engagement & Cultural Enrichment",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "Why do all your friends have tickets to the local production of Annie, and you didn't even know about it? Maybe they had someone to CPE this card . . . and now you will, too. From your city council meetings to concerts to a lecture at the library, this cardholder will take it upon him or herself to research and plan for community events that enhance your family's life experience. Check in with the calendar keeper before you purchase tickets to see your favorite redheaded orphan storm the stage.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Cleaning",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "The pancake batter that fell into the crack on the side of the oven? Last week? It's still there. The pee all over the toilet seat because your toddler can't aim? Still there. The trail of sand in your entryway post-playground? Still there. If you have someone to help deep clean your bathroom and kitchen - lucky you. It's still your responsibility to manage the schedule, task list, payment, etc. Re-deal this card often, as it's a biggie. Tip! Coordinate with the home goods and supplies cardholder so you have cleaning supplies in the house when you need them.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Dental (Kids)",
        "type": "them",
        "suit": "caregiving",
        "grind": false,
        "definition": "Your kid's job: Pick out a rubber band color for his braces. Your job: Carve out a boatload of time for orthodontist appointments and pay for it all, too. Regular dental visits will also keep you plenty busy because each child needs regular cleanings and checkups (it's been six months already?) along with more time-consuming appointments for getting cavities filled. You get a free toothbrush at the end of each visit, so at least there's that.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "First-Aid, Safety & Emergency",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "Before bringing home baby from the hospital, this cardholder has installed the infant car seat (yep, it's a pain, but it's the law). Before a major storm hits, this cardholder will stock up on batteries, bottled water, and other basics. Does your family have an emergency plan for a time when phones may not work and you need to leave the house for a safer place? Working flashlights, candles, and matches for when the power is out? Needless to say, this card is a big one and should be taken seriously.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Gifts (Family)",
        "type": "them",
        "suit": "magic",
        "grind": false,
        "definition": "Gift giving is not about buying something because a date on the calendar compels you to. When you get it right, it's about connection and appreciation. The Magic here lies in finding gifts and writing cards that will make your family members feel special when a holiday, graduation, birthday, or any other occasion comes around. So the next time you see a card with a message that would resonate with your child, you might purchase it even though his or her birthday is a few months away.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Gifts (VIP)",
        "type": "them",
        "suit": "magic",
        "grind": false,
        "definition": "When it comes to Very Important People gift giving, the recipient might be your child's teacher, coach, or the neighbors hosting you two doors down. If regifting is your thing, then CPEing this card can be easy and straightforward. Alternatively, this cardholder may wish to spend more time personalizing gifts and writing heartfelt cards. Either way - or somewhere in the middle - gift giving is an opportunity to connect with and thank the very important people in your life, which requires year-round CPE. Exception: Other kids' birthday gifts stays with the birthday celebrations (other kids) cardholder.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Groceries",
        "type": "them",
        "suit": "home",
        "grind": true,
        "definition": "Your family needs to eat. Can we all agree on that? Good. So how do the pantry and refrigerator get stocked on an ongoing basis? With CPE. This includes noticing what's about to be used up or expire, keeping a running grocery list, and getting to the market . . . ideally before you realize there's not a drop of milk in the fridge. Even if your family orders groceries online and your go-to dinner is frozen pizza, this card requires CPE nearly 365 days a year. Consult the various meals cardholders regularly so you can be sure you have taco shells in the cupboard for taco night.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Grooming & Wardrobe (Player 2)",
        "type": "them",
        "suit": "caregiving",
        "grind": false,
        "definition": "Imagine a conversation that starts innocently enough with a husband asking his wife, Babe, where's my black suit? Then comes, Never mind. Found it. (Pause.) Shoot, it doesn't fit anymore. And the coup de gr\u00e2ce is that their best friends' wedding begins in one hour and the clock is ticking while he blames his wife for failing to buy or rent him a new one. This scenario is all too common as many women in hetero cis gender relationships CPE this card for their husbands. That's right, grown men told me they rely on their wives not only to help them get dressed for special events but also to schedule haircuts, hem their pants, and replace their underwear with holes. If that truly works for you both, God bless. Otherwise, please consider taking this card for yourself, mister. You can do it.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Holiday Cards",
        "type": "them",
        "suit": "magic",
        "grind": false,
        "definition": "Get ready to turn the already chaotic holidays into a job filled with a spreadsheet of personal contacts (did they move?), address labels, and the inevitable returned cards that haunt you until next year. The amount of time - even if you go digital with an e-card - is colossal. Not to mention the nearly impossible feat of taking a family picture with everyone looking in the same direction and smiling! But think about it: It's the one time a year Cousin Susie sees a picture of your growing family. In other words, holiday cards can be a meaningful touch point that makes all the CPE well worth it.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Holidays",
        "type": "them",
        "suit": "magic",
        "grind": false,
        "definition": "Just the time it takes to get holiday ornaments out of storage and unwrap them merits a glass of wine (or three), and that's before the hard work even begins. From cooking to decor to all the logistics, CPEing any holiday is like running an event-planning company - sans the paycheck. When the kids want to attempt their own impossible-to-make (but too-cute-to-resist) Halloween costumes, this cardholder will make the Magic happen. Same goes for figuring out who's hosting Thanksgiving, organizing Easter brunch with second cousins twice removed, and every other holiday and special ritual that your family celebrates. Saving grace: travel, gifts (family) and magical beings cards are not included in this card. In other words, leaving cookies out for Santa isn't on you.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Home Furnishings",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "CPEing this card can be as basic as making sure that every bed has a pillow, you have towels in the closet, and the drinking glasses with cracks get replaced before someone chips a tooth. This card is not a one-time deal, as there's regular inventory to be taken and just when you could use that 20 percent off Bed Bath and Beyond coupon, it's nowhere to be found. Kindly discuss with the points, miles, and coupons cardholder so you're buttoned up for your next trip to the store.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Home Goods and Supplies",
        "type": "them",
        "suit": "home",
        "grind": true,
        "definition": "This card requires CPE of everything from laundry detergent, batteries, and lightbulbs, to toothpaste, coffee filters, and trash bags! Expect to be astounded by how your parents' generation pulled this off without Amazon.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Home Purchase/Rental, Mortgage & Insurance",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "If you aspire to be a homeowner with a mortgage one day, then when the time comes, you'll need to CPE the purchasing process. If you're already a homeowner with a mortgage, this cardholder oversees title insurance, reviewing an infinite amount of statements, and possible refinancing. If you're looking to rent, then you'll be the one to scour listings, fill out applications, and furnish your credit score. You'll also handle home insurance (whether you rent or own), including evaluating the appropriate policy and filing claims if needed. Grab this card fast if paperwork and record-keeping are your strong suits.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Homework, Projects & School Supplies (Kids)",
        "type": "them",
        "suit": "caregiving",
        "grind": true,
        "definition": "Has homework become a family affair? Even if you swore you'd never be the kind of parent who micromanages and digs in to your kids' assignments, you at least need to be in the know if they don't do their homework and be available if your child needs support. CPEing this card means not only taking the lead on daily assignments but also school projects and enrichment learning at home. If your child needs poster board for Star Student of the Week or munchies for Snack Day, that's also on you, as well as outfitting your child for Wacky Winter dress-up day and providing two dozen (hand-signed!) Valentine's Day cards on February 14. This is an endless job, so re-deal this card often.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Hosting",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "Whether it's a backyard barbecue, potluck dinner, or any other get-together in your home or elsewhere, hosting often entails sending out invites, planning the menu, filling a vase with flowers, and setting the table (and maybe a separate one for the kids). The downside to holding this card: You may forget to have fun at your own shindig as you bend over backward to ensure everyone else does. The upside: no need for a workout on hosting days, as you will probably hit 10,000 steps before you sit down to eat (if you get to at all).",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Lawn & Plants",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "If you've got a lawn, a backyard or balcony vegetable garden, or just a struggling succulent by your kitchen window, then you know it takes careful CPE to keep your greenery alive, no less thriving. Even if you have a helpful gardener to lend you his or her green thumb, it's still on you to keep an eye on limp or leggy plants that may need a shot of Miracle-Gro.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Magical Beings (Kids)",
        "type": "them",
        "suit": "magic",
        "grind": false,
        "definition": "By holding this card, you hold the CPE required to keep your child's dreams and imagination alive. When it comes to the Tooth Fairy, Elf on the Shelf, Santa Claus, the Easter Bunny, the Mensch on a Bench, Saint Patrick's Day leprechauns, the Switch Witch, or any other magical being that you introduce into your family, believing in the unbelievable disappears quickly. Put in the time and effort today to make the Magic come to life.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Mail",
        "type": "them",
        "suit": "home",
        "grind": true,
        "definition": "Does every item in your home have a proper place and is there a system to get them there? Are those that don't get donated?",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Meals (School Lunch)",
        "type": "them",
        "suit": "home",
        "grind": true,
        "definition": "If you're this cardholder, toss the idea of a relaxing night out the window because it's time to start packing lunch for the kids. Don't let all the #schoollunch pics on Instagram get you down. Your Fair Play Minimum Standard of Care could be a protein bar, an apple, and a bottle of water if that's all your kid will eat. And even if your child's school provides lunch, you still have to take the lead and sign up for this program and provide lunch money to cover pizza Fridays.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Meals (Weekday Dinner)",
        "type": "them",
        "suit": "home",
        "grind": true,
        "definition": "If you have never CPE'd dinnertime in a family with kids (particularly young ones), then no words can really do justice to this nightly circus. The ringleader plans a weekly menu, consults the groceries cardholder to have the ingredients at home, then cooks, ensures that the food actually gets eaten, and ideally uses this forced family time to have quality conversations about everyone's day. Men: Kudos to you if cooking is one of your domestic contributions, but that's just one word on this doozy of a card. Hero status isn't awarded for just pulling out a frying pan.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Meals (Weekend)",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "Why do weekend meals feel more burdensome than weekday meals? Oh, let us count the ways. Maybe it's because there are twice as many of them than on a school day. Perhaps it's because they amplify the kitchen mess exponentially. Or because when you make it to Saturday night, you're still only halfway there. Weekend meals deserve their own card - who wants it? Psst. Serving lunch at 4 p.m. doesn't cut it unless midday meltdowns are your thing.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Medical & Healthy Living (Kids)",
        "type": "them",
        "suit": "caregiving",
        "grind": false,
        "definition": "From stocking up on standard pain relievers and regular checkups at the pediatrician to seeing specialists as needed, your kids' medical care requires tremendous research, coordination, and time. Healthy living may also include buying vitamins and healthy snacks (in collaboration with the groceries cardholder) and inspiring an active lifestyle with family exercise or other heart-pumping activities. This cardholder also takes the lead stocking up on items like hand sanitizer, sunscreen, and bug spray. Exception: You're off the hook from dealing with health insurance unless that's your card, too.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Memories & Photos",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "God bless those parents who make photo albums every year and who can rest assured that if the basement flooded, all their old photos are scanned and backed up in the cloud. No need to aim that high, but if you hold this card, your family's lifelong memories are nevertheless in your hands. When a photo collage is needed for Student of the Week, it's time to cue the music . . . and kiss some of your time goodbye. When the boxes of your child's artwork that you've been collecting since kindergarten threaten to take over every wall in your home, it's time to consult with the storage and organizing cardholder. Do we keep it all or secretly recycle?",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Money Manager",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "If you're taking the lead on this card, you are intrepid because managing a family's budget is no small feat. Planning for the present and preparing for the future is a good start, but there's so much more to CPEing this card. Short and long-time budgeting. Paying off debt. Retirement planning. College savings and 401(k) investments. And of course there's also the fun that rolls around every April 15. Day-to-day cash and bills is a separate card, and these cardholders will collaborate often.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Packing & Unpacking (Travel)",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "You're not alone if packing for a trip with kids leaves you questioning if it's worth going on the trip at all. It feels like moving the army, only your troops may be too young to pack and carry their own gear. Rookie mistake if just before takeoff you discover the iPad battery is at 1 percent.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "School Breaks (Kids, Non-Summer)",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "For your kids' non-summer vacations like winter and spring break, you have plenty of advance notice to plan. Still, don't wait too long. The holidays creep up quickly. But when it comes to parent-teacher conferences, school construction, snow days, smoke days, and every other scenario that has you scrambling for childcare, this cardholder must be able to pivot quickly and CPE like a pro.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "School Forms (Kids)",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "Every freakin' form from school that requires review, including your child's enrollment forms, notices for overdue library books, and permission slips for field trips is now in your hands. School forms may come via email (check your spam folder), crumpled up in your child's backpack, on a school website, or via the school portal, which you're supposed to know how to log onto and navigate by now but remains one of life's mysteries. School forms are enough for this cardholder; direct contact with teachers is the responsibility of the teacher communication cardholder.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Self-Care (Player 2)",
        "type": "them",
        "suit": "caregiving",
        "grind": false,
        "definition": "Each of you gets a self-care card regardless of how many children and other responsibilities you have. For some, a daily multivitamin, a good book, and a hot bath fit the bill. Others may have weekly therapy or wellness appointments, and also set aside regular time to exercise. No guilt or shame allowed. Take this important time for you and allow your partner to do the same.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Special Needs & Mental Health (Kids)",
        "type": "them",
        "suit": "caregiving",
        "grind": true,
        "definition": "Obviously this card needs to be CPE'd with tremendous care if your child has special needs or mental health issues. From identifying the problem to finding the best resources, scheduling appointments, monitoring medication, planning treatment, handling a school IEP, and beyond, this cardholder will steer the ship that guides your child safely and with love. It takes a village, so your Planning should include how to utilize any help from your wider support system to create an environment that fosters progress.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Storage, Garage, & Seasonal Items",
        "type": "them",
        "suit": "home",
        "grind": false,
        "definition": "If you have a storage unit or a garage, do you have any idea what's inside it? If not, now is the time to get it organized and have a CPE plan in place before someone asks, Honey, where's Zach's sleeping bag? He has a sleepover tonight.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Teacher Communication (Kids)",
        "type": "them",
        "suit": "caregiving",
        "grind": false,
        "definition": "Every teacher and child is different, so this cardholder's responsibility could be as minimal as showing up to the parent-teacher conference once or twice a year. Or your communication may involve a weekly email exchange or speaking daily and face-to-face to discuss homework and your child's unique learning needs. As the CPEer, it's up to you to proactively keep tabs on your kiddos in their school environment to identify potential challenges and work with their teachers to help them thrive.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Transportation (Kids)",
        "type": "them",
        "suit": "out",
        "grind": true,
        "definition": "Full. Time. Job. Whether your kids take a bus, carpool, walk, or have a chauffeur (aka you), managing their transportation can be a beast. On any given day, you could be spending countless hours transporting your kids to school or coordinating with those who are pitching in with drop-offs and pickups. Add on getting them to and from playdates, after-school activities, camp, and every other place they need to be seven days a week and we've come full circle: Full. Time. Job.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Travel",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "When you're thinking about taking a trip, the who/where/ when/how formula has endless permutations. If you add in any extended family, best of luck as you navigate how to get everyone to the same place at the same time on a budget, where hopefully fun awaits. Even a day trip for a friend's wedding requires someone to handle logistics, so just because you're not jet-setting to a white sand beach doesn't mean you're off the hook from CPEing this card.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Weekend Plans",
        "type": "them",
        "suit": "out",
        "grind": false,
        "definition": "Socializing as a family can check off several boxes, including getting your kids out of the house and off screens, seeing your friends, and your kids seeing their friends. (Lucky for you if you like the parents of your kids' BFFs.) But all this box checking requires much CPE, so think it through before you offer to organize a picnic in the park for your BFFs and the twenty kids among them.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "School Transitions",
        "type": "them",
        "suit": "caregiving",
        "grind": false,
        "definition": "If your child needs to apply to a new school (kindergarten through college), this card may include tours, filling out applications, writing essays, ordering transcripts, test preps for the ACT and SAT, and more. And that work only begins after you've completed loads of research about where to apply, considered what might be the best fit for your child, and created your best chances to get in. (Is the lottery really just a lottery? Better find out.)",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Bedtime Routine (Kids)",
        "type": "suppressed",
        "suit": "caregiving",
        "grind": true,
        "definition": "It can be super rewarding when nighttime includes bonding over the day's events, cuddling like nobody's business, or lying quietly with your precious child. Not so much when this routine becomes a battle over bedtime. Pleas for just one more glass of water or five more minutes of reading can test your patience and fortitude (no wonder Go the F**k to Sleep is a bestselling book). While one person needs to take the CPE lead, the other is not home free (i.e., this is not an ideal window of time for you to hit the couch and start flipping channels). Your collaboration is encouraged. Try this: One partner holds the bathing and grooming (kids) card to get the party started while the other is responsible for jammies, stories, and lights out. Keep in mind that this routine won't last forever. Before you know it, your babies will be teenagers and you'll be going to sleep long before them.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Charity, Community Service & Good Deeds",
        "type": "suppressed",
        "suit": "out",
        "grind": false,
        "definition": "You want to give back to your community, religious institution, your kids' schools, or whatever causes are meaningful to you or to others who ask for your support. This cardholder is generous with his or her time (making the meatloaf for the neighborhood meal train) and also your family's money, so don't forget to consult the money manager before making pledges at the annual school fundraiser you can't afford.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Discipline & Screen Time (Kids)",
        "type": "suppressed",
        "suit": "magic",
        "grind": true,
        "definition": "While it's no fun to discipline kids, the Magic is in setting critical boundaries and giving them tools that will impact how safe they feel and how they conduct themselves for a lifetime. The goal is not for one parent to be responsible for discipline while the other holds fun! and playing forever. That's why this card requires ongoing collaboration between partners, with one cardholder at a time taking the lead. For example, screen time. This came up as the most discussed disciplinary issue within families. It's a biggie, and if you hold this card, you may conduct research about an appropriate amount of screen time for your kids and consult with your partner about reasonable boundaries along with the parameters for taking it away; then you'll each enforce your family's rules and consequences on an ongoing basis.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Dishes",
        "type": "suppressed",
        "suit": "home",
        "grind": true,
        "definition": "It's no wonder so many articles about domestic labor begin and end with dishes - the metaphorical third rail of chores. This cardholder is responsible for washing and drying by hand and/or loading and unloading the dishwasher - for every meal. The upside? Your partner doesn't get to criticize how you put the plates in the wrong way. Re-deal this card often to avoid thrown dishes.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Dry Cleaning",
        "type": "suppressed",
        "suit": "home",
        "grind": false,
        "definition": "Even something as seemingly straightforward as dry cleaning requires step-by-step CPE. This card entails having a bin/bag at home, ensuring the appropriate clothes get into that bin/bag, finding a dry cleaner, knowing when it's open so you can coordinate drop-off and pickup, and taking off the plastic wrapping before hanging clothes back in the closet. If the mutually agreed-upon MSC is that all clothes come back home, this cardholder is responsible for tracking down their spouse's favorite shirt when it goes missing or negotiating a refund. (And maybe finding a new dry cleaner, too.)",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Extended Family",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "Someone's got to remember to call Great-Aunt Mary on her ninetieth birthday. Same goes for scheduling playdates with cousins if you want the kids to have a relationship (even if you're the one who always ends up doing the two-hour roundtrip drive). Depending on how much you and your partner value this card, this CPEer might plan frequent get-togethers with relatives or just be the one to remember to invite Uncle Norman to Thanksgiving dinner knowing full well he'll have one too many drinks and talk politics all night.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Friendships & Social Media (Kids)",
        "type": "suppressed",
        "suit": "caregiving",
        "grind": true,
        "definition": "Do you know who your kids are playing with IRL and online? Unless your children come home from school in tears confiding in you, how would you know if they're hanging with the wrong crowd, feeling left out, or being bullied? Make it your business to plug in (get it?) to your kids' social dynamics online and also help plan and supervise playdates, sleepovers, and any other kid get-togethers.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Fun & Playing (Kids)",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "Maybe you've forgotten that parenting is fun! So fun that after you take your kids to the indoor water park they begged you to take them to, your ensuing urinary tract infection feels totally worth it. Seriously, childhood should include interactive playful moments as a family on a regular basis, and those moments and memories are, in fact, magical (UTI notwithstanding). This cardholder will CPE ongoing time for fun, such as arranging to throw a football around in the park at twilight, creating a weekly family game night, or any other activities your kids enjoy. Holding this card can be a blast, so be sure to hand it back and forth regularly with your partner and spread the fun around.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "GESTURES OF LOVE (KIDS)",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "Would your kid report random acts of kindness from you if asked? (i.e. flowers at their recital)",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Hard Questions (Kids)",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "Why do I have hair growing under my armpits? What does it mean when the other kids talk about \u2018doing it'? Enough said. You and your partner may re-deal this card to each other based on the question at hand or which child is asking, but when it's your turn, be prepared as best you can to research and impart thoughtful, accurate, and age-appropriate wisdom. Spoiler alert: The questions get tougher and the stakes get higher as your kids grow up.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Home Renovation",
        "type": "suppressed",
        "suit": "wild",
        "grind": false,
        "definition": "Wild cards fall on a spectrum in terms of how life-altering and time-consuming the predicament can be. Obviously installing new toilets or kitchen cabinets is not as serious an endeavor as keeping your newborn nourished or managing a parent's chemotherapy schedule. Still, it sure would be nice to have a working toilet and a functional kitchen, so someone's got to do the work to make that happen.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Informal Education (Kids)",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "Do you remember who taught you how to hold your breath under water? Throw a ball? Ride a bike? Of course you do, and so will your child as soon as this cardholder gets to work. Kudos to you for the countless hours you'll spend hovering over a bike seat with your back wrenched - that's the Execution - but pay special attention to the Conception and Planning, as well. Start thinking now about those special milestone moments and life skills you want to teach your children. (Hint: If your kid is the only one in class still wearing Velcro sneakers, you might want to remedy that by teaching him to tie his shoes.)",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Marriage & Romance",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "#herofail is what one mom called it when her husband scheduled a series of surprise romantic dinners on Thursday nights not realizing that's when she holds the transportation (kids) card for their daughter's play rehearsals. Her husband's heart was in the right place, but this card (like all of 'em) takes Conception, Planning, and Execution - emphasis needed on the Planning in his case. This cardholder might be the one who, for example, initiates a regular date night (on a night that works!). Planning for kid-free time can feel like a logistical nightmare (scheduling a sitter, coordinating calendars, and agreeing on details), but cardholders who pull it off report it's well worth it.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Middle of the Night Comfort (Kids)",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "Define middle of the night as you see fit, because when daylight savings time hits, you may think of this as the way too early to be morning card that leaves you dog-tired and at your wit's end. (It's amazing how just one hour can do so much damage!) Bad dreams at 3 a.m., cries of I can't sleep! just when you're curled up in bed to watch TV, and help with trips to the toilet for that stomach bug going around are all in this cardholder's hands. As one woman explained it, When my husband has middle-of-the-night duty, I refer to this as the \u2018Don't call Mom' card!",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Showing Up & Participating",
        "type": "suppressed",
        "suit": "magic",
        "grind": false,
        "definition": "One dad admits that he spent nearly a month complaining about the daddy/daughter dance at school, dreading everything from the small talk with other dads to the awkwardness of busting out his moves. Ultimately he went, and it turned out to be pure Magic for both him and his growing-up-too-fast fourth grader. You've got to show up for and participate in your child's events, big and small, for the Magic to happen. Re-deal this card frequently to make sure it does.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Social Plans (Couple)",
        "type": "suppressed",
        "suit": "out",
        "grind": false,
        "definition": "In theory, it's as simple as texting your friends to get together, picking a date/time/place, and showing up. But that's not how it works in real life, and certainly not how it works after having kids. For starters, you want your partner's buy-in for who you're getting together with, and you'll need to get a babysitter, so consult with the childcare helpers cardholder. Then, syncing calendars (pre-bedtime? after the championship game?) will make you question if it's really worth the effort. If you actually make it out the door without cancelling, you have earned a bonus cocktail.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Watching (Kids)",
        "type": "suppressed",
        "suit": "magic",
        "grind": true,
        "definition": "If you have a little one, then you know that having eyes on your child is endless at the baby/toddler stage. Older children need watching, too, like preventing your ten-year-old from spraying WD-40 all over the living room when he mistakes it for air freshener (true story), or walking by your teen's bedroom door constantly to make sure it's open while a friend is over. Good news: It's amazing what can happen when you put down your phone and really watch your kids. Watching a one-year-old may feel uneventful, but this is when you witness your child's first steps! Depending on what time of day you hold this card and the age of your child, you may also be responsible for taking the lead on a number of other cards, such as diapering and potty training, bedtime routine, meals transportation (kids), and tidying up. (If you build a fort in your living room while you're on watch, then it's only fair that you break it down and tidy up before re-dealing this card to your spouse or other caregivers.)",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "New Job",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "When you start a new job, you bring your A game daily, which may mean being the first one in, the last one to leave, working weekends, and doing whatever it takes to make an impression. Ask your partner to step up to the plate by taking some of your household and childcare cards while you concentrate on knocking it out of the park at work.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Aging/Ailing Parent",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "Sandwich generation, anyone? From managing medications, meals, and doctors' appointments to making visiting a priority, caring for an aging/ailing parent can be all-consuming, not to mention the emotional challenge of this inevitable but often unexpected new caregiver role. Even if you outsource some of the work or can afford in-home or assisted-living support, CPEing this card requires all the love in your heart and a good chunk of your time.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Birth Control",
        "type": "unassigned",
        "suit": "caregiving",
        "grind": false,
        "definition": "Why is this a separate card instead of being a component of self-care or medical? Because the woman should not be the she-fault cardholder, meaning either of you can claim this card. If having a baby is not on you and your partner's agenda, this cardholder will research contraception options, which may include buying condoms, the pill, an IUD, or scheduling a vasectomy. (Not sure if being too tired to have sex at all is considered a form of birth control? Ask around.)",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Death",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "As if the loss of a loved one isn't unbearable enough, there are also many important items of business requiring your time, including arranging a funeral, a shiva or other spiritual or religious rituals, and then acknowledging those who paid their respects. If handling any will or estate issues and packing up your loved one's home and belongings are your responsibilities, prepare to be holding this card for quite some time.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Diapering & Potty Training (Kids)",
        "type": "unassigned",
        "suit": "caregiving",
        "grind": true,
        "definition": "Let's be clear: This cardholder will not be the only one changing diapers. Expect to re-deal this card often. While you are the lead, you will, however, be the one responsible for maintaining inventory, including ordering new diapers or washing cloth ones, and getting the soiled ones out of your house ASAP. This cardholder will also oversee potty training your kids, including cleaning up the mistakes along the way. When you're finally a diaper-free home, you may still find yourself wiping the butt of your first grader, who is perfectly capable of doing it on his or her own. This will end one day - promise.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Extracurricular (Kids, Non-Sports)",
        "type": "unassigned",
        "suit": "out",
        "grind": true,
        "definition": "Let's say your daughter begs to be in the school play - with rehearsals on the same days that she already has Girl Scouts. This cardholder must sleuth out what the extracurricular commitment entails and how to juggle it within the context of the family calendar.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "First Year of Infant's Life",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "Unsettled hormones, sleep deprivation, and a ravaged body are enough to make this a Wild card, not to mention that your time has all but disappeared. The days of having even a consecutive two-hour stretch of time to be productive are a vague memory as you drown in nursing/feeding, diaper changing, comforting your baby around the clock, and an innumerable amount of other to-dos all courtesy of your bundle of joy. Make no mistake: The first year of an infant's life is not only a Mom thing. There's plenty for Dad to do, too. While you're trying to survive another day, let him dig into the Daily Grind cards: laundry, dishes, garbage, groceries, and taking the lead on meals (weekday dinner).",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Gestures of Love (Kids)",
        "type": "unassigned",
        "suit": "magic",
        "grind": false,
        "definition": "You know that mom who leaves handwritten notes in her kid's lunch box that read I love you to the moon and back? Or the dad who drops by basketball practice for a quick hug? With some thought, time, and effort - some CPE, if you will - that parent could be you, making every day a little more special for everyone.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Glitch in the Matrix/ Daily Disruption",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "The last thing in the world you have time for is dealing with the unexpected disruption of a fender bender, a computer virus, a flooded basement . . . or having the phone ring and seeing the dreaded school phone number. Your child has lice . . . can you come pick her up? You don't get a vote when a Wild card lands in your lap, but you do get to ask for a helping hand from your partner when a daily disruption torpedoes your day. Remember: Leaving work early because of X, Y, or Z is not on you unless you currently hold this card.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Job Loss & Money Problems",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "Serious financial issues like unemployment or bankruptcy require heavy lifting that may include networking, job interviews, court appearances, and many other time-sucking items of business. Of course you're both impacted by the stressful issue at hand, but this cardholder takes the lead on resolving it while consulting with the money manager so your family's budget can be modified accordingly.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Moving",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "There's a reason why moving is typically on the Top 10 list of hellish events; it can be as stressful as the death of a loved one, divorce, or unemployment. The process of purging, packing, relocating, organizing, storing, and living in disarray is all-consuming, disruptive to your family's routines, and basically upends your life. Good news: It's temporary. Bad news: It's a CPE doozy. Who is better equipped to take the lead?",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Parents & In-Laws",
        "type": "unassigned",
        "suit": "caregiving",
        "grind": false,
        "definition": "Whether your parents and/or in-laws live nearby or thousands of miles away, it's a relationship that can require a fair amount of TLC and CPE. Someone's got to call them regularly, help them figure out how to turn on their TV with one of their 100-year-old remotes, and invite them to holiday or birthday celebrations. (Or maybe not.)",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Partner Coach",
        "type": "unassigned",
        "suit": "magic",
        "grind": false,
        "definition": "Every time you help your loved one with his or her work, you are engaging in the highly invisible work of partner coaching. When you edit an email before your husband sends it to his boss, help him plan an important outing with a client, or lie in bed at night thinking through how he could be considered for a promotion, you are doing the loving and often unrecognized work of coaching your partner. This card can be one-sided (that is, a wife helping a husband but not the other way around). The aspiration is to hand off the card more equitably so both partners can benefit from each other's support and advice.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Points, Miles & Coupons",
        "type": "unassigned",
        "suit": "out",
        "grind": false,
        "definition": "Do you know if you have enough airline miles to fly free on your next trip? And also bring your partner along for the ride? This cardholder keeps track of miles, credit card points, coupons, and all those big money savers. Thoughtful CPE of this card is a must - getting that awesome buy one, get three free deal only happens when you have your coupon stash organized and in hand at the checkout line.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "School Breaks (Kids, Summer)",
        "type": "unassigned",
        "suit": "out",
        "grind": false,
        "definition": "The CPEer of this card may begin researching options for camps or other summer activities months in advance. This workload lasts until the minute all the required forms and fees are taken care of and every day is covered (up to 90 days, but who's counting?).",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "School Service (Kids)",
        "type": "unassigned",
        "suit": "caregiving",
        "grind": false,
        "definition": "When you agreed to chaperone the museum field trip, it seemed like a worthwhile opportunity to spend time with your child and his classmates. Which it was . . . until you found yourself sitting at the back of a school bus trying not to puke. This card pulls you into a time-sucking vortex that includes reading through a chain of 43 emails (was reply all really necessary?), school-sponsored lectures (another one?), and baking cupcakes (nut free?). And what's up with schools requesting volunteers to be class moms? Dads make excellent class parents, too, ya know.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Serious Illness",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "When a child falls ill, the implications can be life changing. If you are managing someone else's illness, it can require hours of research, doctors' appointments, treatment plans, and medication monitoring. Thankfully, you can ask your partner to step in and support you. If, however, it's you or your spouse who is ill, the healthier person may need to take on more cards in addition to holding this Wild card. Rather than allowing resentment or fear to build, ask for what you need and re-deal as circumstances change.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Spirituality",
        "type": "unassigned",
        "suit": "magic",
        "grind": false,
        "definition": "This card looks and feels different to everyone - perhaps even within your own home - so all the more reason you should consult your partner in the Planning to make sure you're on the same page. (Ahem, you don't have carte blanche to join a cult just because it's your card.) It may involve attending church/synagogue/mosque, planning events like communion or a bar/bat mitzvah, establishing a relationship with clergy members, enforcing dietary beliefs, creating a family meditation ritual, or any number of ways you decide to make spirituality a part of your family's life.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Thank You Notes",
        "type": "unassigned",
        "suit": "magic",
        "grind": false,
        "definition": "Why would the dreaded task of writing thank-you notes be considered Magic? Because thank-you notes are an opportunity to express gratitude to people who inspire you to feel it. If you have family dinner with friends, you might send an email letting them know how wonderful it was to spend time together. When a colleague delivers a huge bag of hand-me-downs for your kids, that thoughtfulness merits a note. Imagine a life where sleep-deprived postpartum moms are not saddled with writing thank-you notes for baby gifts. That can be your reality, as either partner is capable of CPEing this card.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Tutoring & Coaching",
        "type": "unassigned",
        "suit": "out",
        "grind": false,
        "definition": "Take this card if you take the lead on extra school enrichment and/or you are the coach of your kids' Little League teams. Some families utilize school-based options while others pursue tutoring companies outside of school or hire tutors/ coaches for kids' academics, sports, or special interests like playing a musical instrument. In every case, this cardholder needs to research the options, confirm availability with the calendar keeper, and assess progress on an ongoing basis. If your kid still can't hit the ball, it might be time for a new coach . . . or a new sport.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Unicorn Space",
        "type": "unassigned",
        "suit": "unicornspace",
        "grind": false,
        "definition": "What makes you uniquely you and how do you share it with the world? Unicorn Space is about the passion and purpose that drive you to be the best version of yourself, and what's on the line - your identity, fulfillment, and marriage - if you don't nurture what makes you come alive. It may seem like a fairy tale to carve out time to get back to playing piano again or research the business idea that you've back-burnered since becoming a parent, but it's time to reclaim your gifts beyond your career and family without needing permission or feeling guilt. Note: An exercise class, a mani/pedi, hitting a sports bar with a buddy, or catching up on your social media feeds are not Unicorn Space. Your career may be if it's a Category 5 storm of passion. Be realistic, but think big as you work to discover or reclaim your essence and talents. Both you and your partner must take this card!",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Values & Good Deeds (Kids)",
        "type": "unassigned",
        "suit": "magic",
        "grind": false,
        "definition": "You know those families who you look up to because the kids have rock-solid values? This kind of Magic takes time and consistency. In other words, one visit to the soup kitchen does not a good kid make. If you want to be the family who feeds the homeless every Thanksgiving, start researching places to volunteer. If you want to make birthdays feel special without any semblance of materialism, start a tradition like a family hug on the time your child was born. (Unless it's in the middle of the night - then wait until the morning.) Soon you'll be the family who everyone else is talking about for the right reasons.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    },
    {
        "userId": "",
        "name": "Welcoming a Child Into The Home",
        "type": "unassigned",
        "suit": "wild",
        "grind": false,
        "definition": "Never mind how often you're puking and falling asleep on the couch by 6 p.m. Preparing for a baby increases your mental, physical, and emotional load exponentially. While you're going to constant doctors' appointments, registering for baby products that you never even knew existed (a Boppy pillow?) or that you would need (Nursing bras?), and listening to unwanted parenting advice from every other woman shopping in the baby basics aisle, ask your more hormonally balanced partner to take some additional cards. Ask him to hold on to them while you're in labor because, um, you're in labor. And while he's at it, he may just as well hold on to them while you recover from giving birth.",
        "conception": "",
        "planning": "",
        "execution": "",
        "msc": ""
    }
]