export async function sendPoll(token, state) {
  return fetch('/api/poll', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "x-access-token": token
    },
    body: JSON.stringify(state)
  })
    .then(data => {return data.json()})
}
