export async function getDeck(token, cardsId) {
  if (cardsId) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return await fetch("/api/decks/" + cardsId, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        if (data === null) {
          return null;
        }
        if (data.message === "Failed to authenticate token.") {
          throw new Error("Your login has expired. Please re-enter your details.");
        }

        return data;
      })
      .catch((error) => console.log(error));
  } else {
    throw new Error("No cardsId provided");
  }
}

export async function saveDeck(token, cards, cardsId) {
  if (cardsId) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({cards: cards}),
    };

    return await fetch("/api/decks/" + cardsId, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch((error) => console.log("error", error));
  } else {
    var myHeadersPost = new Headers();
    myHeadersPost.append("Content-Type", "application/json");
    myHeadersPost.append("x-access-token", token);
    var requestOptionsPost = {
      method: "POST",
      headers: myHeadersPost,
      body: JSON.stringify({cards: cards}),
    };

    return await fetch("/api/decks", requestOptionsPost)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch((error) => console.log("error", error));
  }
}

export async function deleteDecks(token, cardsId) {
  if (cardsId) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
    };

    return await fetch("/api/decks/" + cardsId, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch((error) => console.log("error", error));
  } else {
    console.log("No cardsId provided");
  }
}
