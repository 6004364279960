import React, { useState, useEffect } from "react";
import {
  getSettings,
  createSetting,
  updateSetting,
} from "../services/UserOptions";
import useToken from "./useToken";

const FamilyLink = (family) => {
  const { token } = useToken();
  const [settings, setSettings] = useState([]);
  const [linkCode, setLinkCode] = useState();
  const [partnerLinkCode, setPartnerLinkCode] = useState();

  const link = async () => {

    //take the value of familyId and update the setting item with the name "linkedToUser" to that value
    if (partnerLinkCode !== null) {
      const settingItem = settings.find(item => item.name === "linkedToUser");
      const id2 = settingItem ? settingItem._id : null;
      if (id2 === null) {
        await createSetting(token, {name: "linkedToUser", value: partnerLinkCode});
      } else {
        await updateSetting(token, {value: partnerLinkCode}, id2);
      }
    }
  };

  useEffect(() => {
    if (!token)
        return;

    getSettings(token).then((settings) => {
      console.log(settings);
      //check the settings array for an item with the name "linkedToUser". If it exists, set the familyId to the value of that item.
      //if it doesn't exist, set the familyId to null
      if (settings.find((item) => item.name === "linkedToUser")) {
      } else {
        //check the settings array for an item with the name "shareKey". If it exists, set the linkCode to the value of that item.
        //if it doesn't exist, set the linkCode to Math.random().toString(36).slice(7).toUpperCase()
        if (settings.find((item) => item.name === "shareKey")) {
          setLinkCode(settings.find((item) => item.name === "shareKey").value);
        } else {
          //create the setting
          createSetting(token, {
            name: "shareKey",
            value: Math.random().toString(36).slice(7).toUpperCase(),
          });
          setLinkCode(Math.random().toString(36).slice(7).toUpperCase());
        }
      }
      setSettings(settings);
    });
  }, [token]);

  return (
    <>
      <div>
        Ready to start? Send your partner your link code ({linkCode}) or enter
        theirs here.
      </div>
      <label>
        Family code
        <input
          type="text"
          onChange={(e) => setPartnerLinkCode(e.target.value)}
        />
      </label>
      <button onClick={link}>Link</button>
    </>
  );
};
export default FamilyLink;
