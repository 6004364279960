let token = window.localStorage.getItem('access_token');

export const globalData = {
    appName: "Fair Deal App",
    apiUrl: "/api",
    authHost: process.env.REACT_APP_AUTH_APP_URL,
    environment: process.env.REACT_APP_ENVIRONMENT,
    isLoggedIn: false,
    currentUser: null,
};

export const cardTypes = {
    ME: "me",
    THEM: "them",
    UNASSIGNED: "unassigned",
    SUPPRESSED: "suppressed",
  }

export const requestAuthentication = () => {
    return new Promise((resolve) => {
        if (getToken()) {
            console.log("cached token found");
            resolve();
        } else {
            console.log("globaldata", globalData);
            console.log(`no cached token, getting from ${globalData.authHost}`);
            // Listen to messages posted by the iframe
            // Add an iframe to the page
            const iframe = document.createElement("iframe");
            iframe.src = `${globalData.authHost}/?returnUrl=${window.location.href}`;
            iframe.style.display = "none";
            iframe.setAttribute("sandbox", "allow-top-navigation allow-scripts allow-same-origin");
            document.body.appendChild(iframe);
            window.addEventListener("message", async (event) => {
                if (event.origin === globalData.authHost && event.data.type === "token") {
                    if (event.data.status === "valid") {
                    // Receive the token from the iframe and store it in local storage
                    console.log("Received token", event.data.token);
                    setToken(event.data.token);
                    console.log("Received user data", (event.data.userData));
                    setUserData(event.data.userData);
                    authSuccess(event.data.token);
                    resolve();
                    } else {
                        console.log("Received invalid token", event.data);
                        // notify("Redirecting to login...");
    
                        setTimeout(() => {
                            window.location.href = `${globalData.authHost}/login?returnUrl=${window.location.href}`;
                        }, 2000);
                    }
                } 
            });
        }
    });
};

export const getToken = () => {
    return token;
}

export const setToken = (newToken) => {
    token = newToken;
    window.localStorage.setItem('access_token', token);
}

export const getUserData = () => {
    return JSON.parse(window.localStorage.getItem('user_data'));
}

export const getUserEmail = () => {
    const userData = getUserData();
    if (userData === null || userData.email === undefined) {
      return '...';
    }
    return userData.email;
}

export const setUserData = (userData) => {
    window.localStorage.setItem('user_data', userData);
}

export const authSuccess = (newToken) => {
    setToken(newToken);
}

export const logout = () => {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('user_data');
    // notify("Logging you out...");
    setTimeout(() => {
        window.location.href = `${globalData.authHost}/logout?returnUrl=${window.location.href}`;
    }, 3000);
}

export const profile = () => {
    window.open(`${globalData.authHost}/profile`);
}
